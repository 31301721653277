@import "../../scss/variables";

.footer-light {
  display: flex;
  flex: 0 0 50px;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  position: fixed;
  bottom: 0;
  background-color: $footer-header-color-lightmode;
  margin-top: 15px;
  width: 100%;
}

.footer-dark {
  display: flex;
  flex: 0 0 50px;
  flex-wrap: wrap;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  position: fixed;
  bottom: 0;
  margin-top: 15px;
  width: 100%;
  border-top: 1px solid #181924;
  /* Change this color for background*/
  background: $footer-header-color-darkmode;
  color: #ffff;
}
